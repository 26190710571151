import React from "react";
import { Box, Button, Typography } from "@mui/material";

const ServerMonitoring = () => {
    const iframeSrc = "https://app.pm2.io/bucket/65af612fa528eeadd35156a3/backend/overview/servers?token=lq7c62oat7p2ribz9m1fce0zt7gu2mlaykxo15ks93kn1kc0e772ns8zxs88tdd7";

    const openLinkInNewTab = () => {
        window.open(iframeSrc, "_blank");
    };

    return (
        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column', padding: 2 }}>
            <Box sx={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center', marginBottom: 2 }}>
                <Typography variant="h4" component="h1">
                    Server Monitoring
                </Typography>
                <Button
                    variant="contained"
                    color="primary"
                    onClick={openLinkInNewTab}
                >
                    Open PM2 Monitoring in a New Tab
                </Button>
            </Box>



            {/*<Box sx={{ width: '100%', height: '80%', border: '1px solid #ddd', borderRadius: 1, overflow: 'hidden' }}>*/}
            {/*    <iframe*/}
            {/*        src={iframeSrc}*/}
            {/*        width="100%"*/}
            {/*        height="100%"*/}
            {/*        style={{ border: 'none' }}*/}
            {/*        title="Server Monitoring"*/}
            {/*        allow="cookies"*/}
            {/*    />*/}
            {/*</Box>*/}
            <Typography variant="body1" sx={{ marginBottom: 2, textAlign: 'center' }}>
                If the iframe is not loading properly, click the button to view the PM2 server monitoring dashboard in a new tab.
            </Typography>
        </Box>
    );
};
export default ServerMonitoring