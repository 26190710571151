import './style.css'
import "./app/config/i18n";
import axios from "axios";
import './index.css';
import {App} from "./app/App.jsx";
import ReactDOM from "react-dom";
import React from "react";

axios.defaults.withCredentials = true;

// axios.defaults.baseURL = "http://localhost:3200/adminAPI";
axios.defaults.baseURL = window.location.origin === 'http://localhost:5173' ||window.location.origin === 'http://localhost:4173' ? "http://localhost:3200/adminAPI" : "https://beta.backend.iceride.io/adminAPI";


// document.querySelector('#app').innerHTML = <App/>


// Find the root element in your HTML
const rootElement = document.getElementById('app');

// Create a React root and render the App component
const root = ReactDOM.createRoot(rootElement);
root.render(
    <React.StrictMode>
    <App/>
</React.StrictMode>);