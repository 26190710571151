import React, {useState} from "react";
import Datagrid from "../components/Datagrid";
import {
    Avatar,
    Backdrop,
    Box,
    Button,
    FormControl,
    Grid,
    IconButton,
    List,
    ListItem,
    MenuItem,
    Modal,
    Select,
    TextField,
    Typography,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";
import CloseIcon from "@mui/icons-material/Close";
import {useDropzone} from "react-dropzone";
import {useTranslation} from "react-i18next";
import {fileURL} from "../context/constants";

const modalStyle = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    borderRadius: 2,
    p: 4,
    width: "40%",
    maxHeight: "80vh",
    overflowY: "auto",
};

const SubAdmins = () => {
    const [open, setOpen] = useState(false);
    const [adding, setAdding] = useState(false);
    const [id, setId] = useState();
    const [email, setEmail] = useState({value: "", error: false, helperText: ""});
    const [password, setPassword] = useState({value: "", error: false, helperText: ""});
    const [firstName, setFirstName] = useState({value: "", error: false, helperText: ""});
    const [lastname, setLastname] = useState({value: "", error: false, helperText: ""});
    const [mobile, setMobile] = useState({value: "", error: false, helperText: ""});
    const [role, setRole] = useState("subAdmin");
    const [refetch, setRefetch] = useState(1);

    const handleClose = () => setOpen(false);
    const columns = [
        {
            field: "image",
            width: 150,
            renderCell: (params) => <Avatar alt="Cindy Baker" src={fileURL + params.row.image}/>,
        },
        {field: "name", headerName: "First Name", width: 150},
        {field: "lastname", headerName: "Last Name", width: 150},
        {field: "email", headerName: "Email", width: 150},
        {field: "role", headerName: "Role", width: 150},
        {field: "phone", headerName: "Phone", width: 150},
        {
            headerName: "Registered At",
            field: "createdAt",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body2">
                    {new Date(params.row.createdAt).toLocaleString()}
                </Typography>
            ),
        },
        {
            headerName: "Update",
            field: "id",
            sortable: false,
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => {
                        handleOpen(params.row);
                    }}
                >
                    Update
                </Button>
            ),
        },
        {
            headerName: "Delete",
            field: "_id",
            sortable: false,
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="error"
                    onClick={() => {
                        handleDelete(params.row._id);
                    }}
                >
                    Delete
                </Button>
            ),
        },
    ];
    const openAddModal = () => {
        setFirstName({value: "", error: false, helperText: ""});
        setLastname({value: "", error: false, helperText: ""});
        setEmail({value: "", error: false, helperText: ""});
        setMobile({value: "", error: false, helperText: ""});
        setPassword({value: "", error: false, helperText: ""});
        setOpen(true);
        setAdding(true);
    };

    const handleOpen = (data) => {
        console.log(data)
        setOpen(true);
        setFirstName({value: data.name, error: false, helperText: ""});
        setLastname({value: data.lastname, error: false, helperText: ""});
        setEmail({value: data.email, error: false, helperText: ""});
        setMobile({value: data.phone, error: false, helperText: ""});
        setRole(data.role)
        setId(data._id);
    };
    const handleDelete = (id) => {
        toast.loading("Deleting SubAdmin", {id: "DSA"});
        axios
            .get("/deleteSubAdmin/".concat(id))
            .then(({data}) => {
                toast.success("SubAdmin Deleted", {
                    id: "DSA", icon: '✅',  // Optional icon
                    style: {
                        background: '#4CAF50',  // Green background for success
                        color: 'white',          // White text color
                    }
                });
                setRefetch(refetch + 1);
            })
            .catch(() => {
                toast.error("Unable to delete SubAdmin", {id: "DSA"});
            });
    };


    const handleSubmit = () => {
        if (!firstName.value) {
            setFirstName({...firstName, error: true, helperText: "Provide a valid name"});
            return;
        }
        if (!lastname.value) {
            setLastname({...lastname, error: true, helperText: "Provide a valid name"});
            return;
        }
        if (!mobile.value) {
            setMobile({...mobile, error: true, helperText: "Provide a valid phone number"});
            return;
        }

        const formData = new FormData();
        formData.append("image", acceptedFiles[0]);
        formData.append("email", email.value);
        formData.append("name", firstName.value);
        formData.append("lastname", lastname.value);
        formData.append("phone", mobile.value);
        formData.append("password", password.value);
        formData.append("role", role);

        if (adding) {
            toast.loading("Adding SubAdmin...");
            axios
                .post("/addSubAdmin", formData)
                .then(() => {
                    toast.success("SubAdmin Added");
                    setRefetch(refetch + 1);
                    handleClose();
                })
                .catch(() => toast.error("Unable to add SubAdmin"));
        } else {
            formData.append("id", id);
            toast.loading("Updating SubAdmin...");
            axios
                .post("/updateSubAdmin", formData)
                .then(() => {
                    toast.success("SubAdmin Updated");
                    setRefetch(refetch + 1);
                    handleClose();
                })
                .catch(() => toast.error("Unable to update SubAdmin"));
        }
    };

    const {acceptedFiles, getRootProps, getInputProps} = useDropzone({maxFiles: 1});
    const files = acceptedFiles.map((file) => (
        <ListItem key={file.path} sx={{width: "auto", mr: 1}}>
            {file.path} - {file.size} bytes
        </ListItem>
    ));

    const {t} = useTranslation();

    return (
        <>
            <Grid container justifyContent="space-between" alignItems="center" mb={3}>
                <Typography variant="h4" fontWeight="500">
                    {t("pages.title.subAdmins")}
                </Typography>
                <Button variant="contained" onClick={openAddModal}>
                    Add SubAdmin
                </Button>
            </Grid>

            <Modal open={open} onClose={handleClose} closeAfterTransition BackdropComponent={Backdrop}
                   BackdropProps={{timeout: 500}}>
                <Box sx={modalStyle}>
                    <Box display="flex" justifyContent="space-between" alignItems="center" mb={3}>
                        <Typography variant="h5" fontWeight="500">
                            {adding ? "Add SubAdmin" : "Edit SubAdmin"}
                        </Typography>
                        <IconButton onClick={handleClose}>
                            <CloseIcon/>
                        </IconButton>
                    </Box>

                    {/* Form Fields */}
                    <Grid container spacing={2} direction="column">
                        {[
                            {label: "First Name", state: firstName, setState: setFirstName},
                            {label: "Last Name", state: lastname, setState: setLastname},
                            {label: "Email", state: email, setState: setEmail},
                            {label: "Mobile", state: mobile, setState: setMobile},
                            {label: "Password", state: password, setState: setPassword, type: "password"},
                        ].map(({label, state, setState, type = "text"}, index) => (
                            <Grid container key={index} spacing={2} alignItems="center">
                                <Grid item xs={4} marginTop={2}>
                                    <Typography variant="body1">{label}</Typography>
                                </Grid>
                                <Grid item xs={8} marginTop={2}>
                                    <TextField
                                        size="small"
                                        fullWidth
                                        value={state.value}
                                        onChange={(e) => setState({...state, value: e.target.value})}
                                        error={state.error}
                                        helperText={state.helperText}
                                        type={type}
                                    />
                                </Grid>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Role Dropdown */}
                    <Grid container spacing={2} alignItems="center" mt={2}>
                        <Grid item xs={4}>
                            <Typography variant="body1">Role</Typography>
                        </Grid>
                        <Grid item xs={8}>
                            <FormControl fullWidth size="small">

                                <Select value={role} onChange={(e) => setRole(e.target.value)}>
                                    <MenuItem value="admin">Admin</MenuItem>
                                    <MenuItem value="subAdmin">Sub Admin</MenuItem>
                                    <MenuItem value="complaints">Complaints</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                    </Grid>

                    {/* Image Upload */}
                    <Grid container direction="column" mt={2}>
                        <Typography variant="body1" mb={1}>
                            Image
                        </Typography>
                        <Box {...getRootProps()}
                             sx={{border: "2px dashed #1976d2", padding: 2, textAlign: "center", cursor: "pointer"}}>
                            <input {...getInputProps()} />
                            {files.length > 0 ? (
                                <List>{files}</List>
                            ) : (
                                <Typography variant="body2" color="textSecondary">
                                    Drag & drop an image here, or click to select one
                                </Typography>
                            )}
                        </Box>
                    </Grid>

                    {/* Submit Button */}
                    <Grid container justifyContent="center" mt={3}>
                        <Button variant="contained" onClick={handleSubmit}>
                            Submit
                        </Button>
                    </Grid>
                </Box>
            </Modal>

            {/* Data Grid */}
            <Datagrid columns={columns} refetch={refetch} handleOpen={handleOpen} url="/subAdmins"/>
        </>
    );
};

export default SubAdmins;
