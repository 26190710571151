import ComingSoon from "../shared/ComingSoon";

 const Complaints = () => {
    return (
        <>
        <ComingSoon/>


        </>
    )
}
export default Complaints