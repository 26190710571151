import React, { useEffect, useState } from "react";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    TablePagination,
    Card,
    Grid,
    Typography,
    Skeleton,
    useMediaQuery,
} from "@mui/material";
import axios from "axios";
import toast from "react-hot-toast";

const CustomDatagrid = ({ columns, url, refetch, filter, handleOpen }) => {
    const [page, setPage] = useState(0); // Zero-based page index
    const [pageSize, setPageSize] = useState(10); // Default page size
    const [rows, setRows] = useState([]);
    const [rowCount, setRowCount] = useState(0); // Total number of rows from server
    const [loading, setLoading] = useState(false);

    // Sort state
    const [sortField, setSortField] = useState("updatedAt");
    const [sortOrder, setSortOrder] = useState("desc"); // or 'desc'

    // Check if the screen size is small
    const isSmallScreen = useMediaQuery((theme) => theme.breakpoints.down("sm"));

    const fetchData = async (currentPage, currentPageSize, sortField, sortOrder) => {
        setLoading(true);
        try {
            const response = await axios.get(
                `${url}?page=${currentPage}&pageSize=${currentPageSize}&filter=${filter}&sortField=${sortField}&sortOrder=${sortOrder}`
            );
            const data = response.data;

            setRows(data.rows); // Set current page rows
            setRowCount(data.totalRows); // Set total rows for pagination
            toast.success("Data Loaded!", {
                icon: "✅", // Optional icon
                style: {
                    background: "#4CAF50", // Green background for success
                    color: "white", // White text color
                },
            });
        } catch (error) {
            console.error("Error fetching data:", error);
            toast.error("Failed to load data.");
        } finally {
            setLoading(false);
        }
    };

    useEffect(() => {
        fetchData(page, pageSize, sortField, sortOrder);
    }, [page, pageSize, url, refetch, filter, sortField, sortOrder]);

    const handleSort = (field) => {
        const isAsc = sortField === field && sortOrder === "asc";
        setSortField(field);
        setSortOrder(isAsc ? "desc" : "asc");
    };

    return (
        <Grid container sx={{ justifyContent: "center", padding: isSmallScreen ? 1 : 2 }}>
            <Card sx={{ width: "100%", overflowX: "auto" }}>
                <TableContainer>
                    <Table size={isSmallScreen ? "small" : "medium"}>
                        <TableHead>
                            <TableRow>
                                {columns.map((column, index) => (
                                    <TableCell
                                        key={index}
                                        style={{ width: column.width }}
                                        onClick={() => column.field && handleSort(column.field)} // Enable sorting on header click
                                        sx={{ cursor: "pointer" }}
                                    >
                                        {column.headerName}
                                        {/* Add sorting icon */}
                                        {sortField === column.field && (sortOrder === "asc" ? " ▲" : " ▼")}
                                    </TableCell>
                                ))}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {loading ? (
                                // Render skeleton rows when loading
                                [...Array(pageSize)]?.map((_, index) => (
                                    <TableRow key={index}>
                                        {columns.map((column, colIndex) => (
                                            <TableCell key={colIndex}>
                                                <Skeleton variant="text" width="100%" height={24} />
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : rows?.length > 0 ? (
                                // Render data rows when loading is complete
                                rows?.map((row) => (
                                    <TableRow key={row._id}>
                                        {columns?.map((column, index) => (
                                            <TableCell
                                                key={index}
                                                align={column.align || "left"}
                                                sx={{
                                                    whiteSpace: "nowrap",
                                                    overflow: "hidden",
                                                    textOverflow: "ellipsis",
                                                    maxWidth: column.width || "150px",
                                                }}
                                            >
                                                {column.renderCell
                                                    ? column.renderCell({ row }) || "-"
                                                    : row[column.field] || "-"}
                                            </TableCell>
                                        ))}
                                    </TableRow>
                                ))
                            ) : (
                                <TableRow>
                                    <TableCell colSpan={columns.length} align="center">
                                        No data available
                                    </TableCell>
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </TableContainer>
                <TablePagination
                    component="div"
                    count={rowCount}
                    page={page}
                    onPageChange={(event, newPage) => setPage(newPage)}
                    rowsPerPage={pageSize}
                    onRowsPerPageChange={(event) => setPageSize(parseInt(event.target.value, 10))}
                    rowsPerPageOptions={[5, 10, 20, 50]}
                />
            </Card>
        </Grid>
    );
};

export default CustomDatagrid;
