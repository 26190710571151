import React from "react";
import { Navigate, Route, Routes } from "react-router-dom";
import { useAuth } from "./context/AuthContext";
import { CircularProgress } from "@mui/material";

// Import all components directly (remove lazy loading)
import AppLayout from "./AppLayout";
import AuthWrapper from "./utils/AuthWrapper";
import Login from "./pages/Login";
import Dashboard from "./pages/Dashboard";
import Drivers from "./pages/Drivers";
import SubAdmins from "./pages/SubAdmins";
import Riders from "./pages/Riders";
import Rentals from "./pages/Rentals";
import IntroScreens from "./pages/IntroScreens";
import Payments from "./pages/Payments";
import UserProfile from "./pages/UserProfile";
import LegalTerms from "./pages/LegalTerms";
import HowItWorks from "./pages/HowItWorks";
import BusinessSignup from "./pages/BusinessSignup";
import Corporates from "./pages/Corporates";
import CorporateHome from "./pages/CorporateHome";
import VerifyEmail from "./pages/VerifyEmail";
import CorporateUsers from "./pages/CorporateUsers";
import Pricing from "./pages/Pricing";
import VerifyUserEmail from "./pages/VerifyUserEmail";
import CoverageArea from "./pages/CoverageArea";
import SurgeArea from "./pages/SurgeArea";
import DriverAlerts from "./pages/DriverAlerts";
import Promotions from "./pages/Promotions";
import Complaints from "./pages/Complaints";
import Inspections from "./pages/Inspections";
import PricingRegions from "./pages/PricingRegions";
import ServerMonitoring from "./pages/ServerMonitoring";
import SubAdminAuthWrapper from "./utils/SubAdminAuthWrapper";

const AppRoutes = () => {
    const { isLoggedIn } = useAuth();

    return (
        <Routes>
            {/* Redirect to Dashboard if user is logged in */}
            {isLoggedIn ? (
                <Route index element={<Navigate to="/admin" replace />} />
            ) : (
                <Route index element={<Login />} />
            )}
            <Route index element={<Login />} />
            <Route path={"business-signup"} element={<BusinessSignup />} />
            <Route path="/verifyEmail/:token" element={<VerifyEmail />} />
            <Route path="/verifyUserEmail/:token" element={<VerifyUserEmail />} />

            {/* Pages with layout and authentication */}
            <Route path="admin" element={<AppLayout><AuthWrapper /></AppLayout>}>
                <Route index element={<Dashboard />} />
                <Route path="drivers" element={<Drivers />} />
                <Route path="corporates" element={<Corporates />} />
                <Route path="subAdmins" element={<SubAdmins />} />
                <Route path="riders" element={<Riders />} />
                <Route path="intro-screens" element={<IntroScreens />} />
                <Route path="rentals" element={<Rentals />} />
                <Route path="payments" element={<Payments />} />
                <Route path="pricing" element={<Pricing />} />
                <Route path="profile" element={<UserProfile />} />
                <Route path="legal" element={<LegalTerms />} />
                <Route path="howItWorks" element={<HowItWorks />} />
                <Route path="operational-zones" element={<CoverageArea />} />
                <Route path="surge-zones" element={<SurgeArea />} />
                <Route path="driver-alerts" element={<DriverAlerts />} />
                <Route path="promotions" element={<Promotions />} />
                <Route path="complaints" element={<Complaints />} />
                <Route path="inspections" element={<Inspections />} />
                <Route path="pricing-regions" element={<PricingRegions />} />
                <Route path="server-monitoring" element={<ServerMonitoring />} />
            </Route>

            <Route path="corporate" element={<AppLayout><AuthWrapper /></AppLayout>}>
                <Route index element={<CorporateHome />} />
                <Route path="users" element={<CorporateUsers />} />
            </Route>

            <Route path="subAdmin" element={<AppLayout><SubAdminAuthWrapper /></AppLayout>}>
                <Route index element={<Drivers />} />
                <Route path="riders" element={<Riders />} />
            </Route>

            <Route path="*" element={<Login />} />
        </Routes>
    );
};

export default AppRoutes;
