import React, {useState} from "react";

import {Button, Grid, Typography} from "@mui/material";

import Datagrid from "../components/Datagrid";
import {useTranslation} from "react-i18next";
import RiderDetailsModal from "../components/RiderManagement/RiderDetailsModal";
import SearchBar from "../shared/SearchBar";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import PersonIcon from "@mui/icons-material/Person";
import Chip from "@mui/material/Chip";
import CopyWrapper from "../shared/CopyWrapper"; // Gender-neutral icon
const Riders = () => {
    const [open, setOpen] = useState(false);
    const [modalData, setModalData] = useState(null);

    const handleOpen = async (data) => {
        setModalData(data)
        setOpen(true);
    };
    const handleClose = async () => {
        setOpen(false);
    };
    const columns = [{
        headerName: "ID",
        field: "_id",
        width: 225,
        renderCell: (params) => (<Typography variant="body"><CopyWrapper>{params.row?._id}</CopyWrapper></Typography>),
    }, {field: "name", headerName: "Name", width: 150}, {
        field: "email",
        headerName: "Email",
        width: 150
    }, {field: "phone", headerName: "Phone", width: 150}, {
        field: "gender", headerName: "Gender", width: 150, renderCell: (params) => {
            const gender = params.row?.gender;

            const getGenderProps = () => {
                switch (gender) {
                    case 'm':
                        return {label: "Male", icon: <MaleIcon style={{color: "#2196f3"}}/>};
                    case 'f':
                        return {label: "Female", icon: <FemaleIcon style={{color: "#e91e63"}}/>};
                    // case 'non_binary':
                    //     return {label: "Non-binary", icon: <TransgenderIcon style={{color: "#9c27b0"}}/>};
                    default:
                        // Default to gender-neutral icon if no gender is provided
                        return {label: "Unspecified ", icon: <PersonIcon style={{color: "#9e9e9e"}}/>};
                }
            };

            const {label, icon} = getGenderProps();

            return (<Chip
                    label={label}
                    icon={icon}
                    variant="outlined"
                    size="medium"
                />);
        }
    }, {
        headerName: "Registered At",
        field: "createdAt",
        sortable: false,
        width: 175,
        renderCell: (params) => (<Typography variant="body">
                {new Date(params.row.createdAt).toLocaleString()}
            </Typography>),
    }, {
        headerName: "Details",

        width: 125, renderCell: (params) => (<Button
                variant="outlined"
                color="primary"
                onClick={() => handleOpen(params.row,)}
            >
                Details
            </Button>),
    }, // {
        //   headerName: "Details",
        //   field: "id",
        //   sortable: false,
        //   width: 125,
        //   renderCell: (params) => (
        //     <Button
        //       variant="outlined"
        //       color="primary"
        //       onClick={() => {
        //         // alert(params.row.id);
        //         setModalData(params.row);
        //         handleOpen(params.row._id);
        //       }}
        //     >
        //       Details
        //     </Button>
        //   ),
        // },
    ];

    const {t} = useTranslation();

    const [searchQuery, setSearchQuery] = useState("");

    const handleSearch = (query) => {
        setSearchQuery(query);
        // Optionally, trigger data fetching or filtering based on the search query
    };
    return (<>
            <Grid container alignItems="center" justifyContent="space-between" mb={0}>
                <Grid item>
                    <Typography variant="h1">
                        {t("pages.title.riders")}
                    </Typography>
                </Grid>
                <Grid item>
                    <SearchBar onSearch={handleSearch}/>
                </Grid>
            </Grid>

            {/* Modal  */}
            <RiderDetailsModal open={open} handleClose={handleClose} modalData={modalData}/>

            <Datagrid url={'/riders'} columns={columns} filter={searchQuery}/>
        </>);
};

export default Riders;
