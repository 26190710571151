import React, {useState,useEffect} from "react";
import {
    Avatar,
    Box,
    Button,
    Divider,
    Grid,
    IconButton,
    Modal,
    Skeleton,
    Stack,
    Tab,
    Tabs, TextField,
    Typography
} from "@mui/material";

import CloseIcon from "@mui/icons-material/Close";
import {fileURL} from "../../context/constants";
import {DriverEarnings} from "./DriverEarnings";
import RideList from "../../shared/RideList";
import ComingSoon from "../../shared/ComingSoon";
import FileViewer from "../../shared/FIleViewer";
import CopyWrapper from "../../shared/CopyWrapper";
import DriverDetailsCard from "./DriverDetailsCard";
import {DriverRewards} from "./DriverRewards";
import InspectionDetails from "../Inspections/InspectionDetails";
import VerificationForm from "./VerificationForm";


const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    width: '50%',
    maxHeight: '70vh',
    overflowY: 'auto'
};
// Custom styles for the TextField
const textFieldStyles = {
    width: "300px", // Adjust width as needed
    "& .MuiOutlinedInput-root": {
        borderRadius: "8px", // Rounded corners
        backgroundColor: "#f9f9f9", // Light background
    },
    "& .MuiOutlinedInput-root.Mui-focused": {
        borderColor: "#4caf50", // Green border when focused
    },
    "& .MuiInputLabel-root.Mui-focused": {
        color: "#4caf50", // Green label color when focused
    },
};
const DriverDetailsModal = ({open, handleClose, modalData, carData, driverDetails, verifyDriver, blockDriver,handleRefetch ,updateDriverVerificationStatus}) => {
    const [activeTab, setActiveTab] = useState(0);

    const [blockReason, setBlockReason] = useState(''); // State for the TextField
    const handleTabChange = (event, newValue) => {
        setActiveTab(newValue);
    };
    //console.log(modalData)
    useEffect(() => {
        if (driverDetails?.blockReason) {
            setBlockReason(driverDetails.blockReason);
        }
    }, [driverDetails]);
    return (
        <Modal open={open} onClose={handleClose} sx={{}}>
            <Box sx={{
                padding: 4,
                backgroundColor: "white",
                width: '50%',
                height: '80%',
                margin: "auto",
                mt: 4,
                borderRadius: 2,
                overflow: 'auto'
            }}>

                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h3" sx={{flexGrow: 1, textAlign: 'center'}}>Driver Details</Typography>
                    <IconButton onClick={handleClose}><CloseIcon/></IconButton>
                </Box>

                <Tabs value={activeTab} onChange={handleTabChange}>
                    <Tab label="Driver Info"/>
                    <Tab label="Documents"/>
                    <Tab label="Earnings"/>
                    <Tab label="Ride History"/>
                    <Tab label="Rewards"/>
                    <Tab label="Inspection"/>
                </Tabs>


                <Divider sx={{marginBottom: 4}}
                />

                {activeTab === 0 && (
                    <Grid container spacing={3} marginTop="20px">
                        {/* Driver Details Section */}
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                Driver Details
                            </Typography>
                            <Divider />
                        </Grid>
                        {[
                            { label: "Name", value: modalData?.name },
                            { label: "Email", value: modalData?.email },
                            { label: "Id", value: <CopyWrapper>{modalData?._id}</CopyWrapper> },
                            { label: "Phone", value: modalData?.phone },
                            { label: "City", value: modalData?.city },
                            { label: "Address", value: modalData?.address },
                            { label: "State", value: modalData?.state },
                            { label: "SSN", value: modalData?.socialSecurityNumber },
                            { label: "License Number", value: modalData?.licenseNumber },
                        ].map((field, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Typography fontSize="16px" fontWeight="500">
                                        {field.label}
                                    </Typography>
                                    <Typography fontSize="16px">{field.value}</Typography>
                                </Stack>
                            </Grid>
                        ))}

                        {/* Car Details Section */}
                        <Grid item xs={12}>
                            <Typography variant="h5" gutterBottom>
                                Car Details
                            </Typography>
                            <Divider />
                        </Grid>
                        {[
                            { label: "Model", value: carData?.carModel },
                            { label: "Category", value: carData?.category },
                            { label: "Color", value: carData?.color },
                            { label: "Registration Number", value: carData?.registrationNumber },
                        ].map((field, index) => (
                            <Grid item xs={12} sm={6} key={index}>
                                <Stack direction="row" justifyContent="space-between" alignItems="center" spacing={2}>
                                    <Typography fontSize="16px" fontWeight="500">
                                        {field.label}
                                    </Typography>
                                    <Typography fontSize="16px">{field.value}</Typography>
                                </Stack>
                            </Grid>
                        ))}

                        {/* Block Reason Field */}
                        <Grid item xs={12}>
                            <Stack direction="column" spacing={1}>
                                <Typography fontSize="16px" fontWeight="500">
                                    Block Reason
                                </Typography>
                                <TextField
                                    value={blockReason}
                                    onChange={(e) => setBlockReason(e.target.value)}
                                    placeholder="Enter reason"
                                    variant="outlined"
                                    size="small"
                                    fullWidth
                                    sx={{
                                        "& .MuiOutlinedInput-root": {
                                            borderRadius: "8px",
                                        },
                                        "& .MuiOutlinedInput-root.Mui-focused": {
                                            borderColor: "#4caf50",
                                        },
                                    }}
                                />
                            </Stack>
                        </Grid>

                        {/* Action Buttons */}
                        <Grid item xs={12} container justifyContent="center" spacing={2} marginTop="20px">
                            <Grid item>
                                <Button
                                    variant="contained"
                                    disabled={driverDetails?.isVerified}
                                    sx={{ width: "300px" }}
                                    onClick={() => verifyDriver(driverDetails?._id)}
                                >
                                    {driverDetails?.isVerified ? "Verified" : "Verify"}
                                </Button>
                            </Grid>
                            <Grid item>
                                <Button
                                    color={driverDetails?.isBlocked ? "success" : "error"}
                                    variant="contained"
                                    sx={{ width: "300px" }}
                                    onClick={() => blockDriver(driverDetails?.driverId, !driverDetails?.isBlocked,blockReason)}
                                >
                                    {driverDetails?.isBlocked ? "Unblock" : "Block"}
                                </Button>
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                {activeTab === 1 && (
                    <Grid>
                        <h3>Documents</h3>

                        <DocumentViewer modalData={modalData}/>
                        <VerificationForm driverId={modalData._id} documentVerification={driverDetails} updateDriverVerificationStatus={updateDriverVerificationStatus}/>

                    </Grid>
                )}
                {activeTab === 2 && (
                    <Grid>
                        <DriverEarnings driverId={modalData._id}/>
                    </Grid>
                )}
                {activeTab === 3 && (
                    <Grid>
                        <RideList id={driverDetails?.driverId}  driver={true}/>
                    </Grid>
                )}  {activeTab === 4 && (
                    <Grid>
                     <DriverRewards driverId={driverDetails?.driverId}  />
                    </Grid>
                )}
                {activeTab === 5 && (
                    <Grid>
                     <InspectionDetails driverId={driverDetails?.driverId} refetch={handleRefetch} handleClose={handleClose}/>
                    </Grid>
                )}

            </Box>
        </Modal>
    );
};

function DocumentSection({label, fileUrl, fallbackMessage}) {
    return (
        <Box mb={2}>
            <Typography variant="h6">{label}</Typography>
            {fileUrl ? (
                <FileViewer fileUrl={fileUrl}/>
            ) : (
                <Typography color="text.secondary">{fallbackMessage}</Typography>
            )}
        </Box>
    );
}

function DocumentViewer({modalData}) {
    return (
        <>
            <DocumentSection
                label="Driving License Front"
                fileUrl={modalData?.drivingLicenseFront}
                fallbackMessage="Driving License Front not available"
            />

            <DocumentSection
                label="Driving License Back"
                fileUrl={modalData?.drivingLicenseBack}
                fallbackMessage="Driving License Back not available"
            />

            <DocumentSection
                label="Insurance Document"
                fileUrl={modalData?.insuranceDocument}
                fallbackMessage="Insurance Document not available"
            />
        </>
    );
}


function ProfilePicture({src}) {
    const [loading, setLoading] = useState(true);

    return (
        <Box sx={{display: 'flex', justifyContent: 'center', alignItems: 'center'}}>
            {loading && (
                <Skeleton
                    variant="circular"
                    width={150}
                    height={150}
                    sx={{justifySelf: 'center'}}
                />
            )}
            <Avatar
                alt="Profile Sharp"
                src={fileURL.concat(src)}
                sx={{
                    width: 150,
                    height: 150,
                    justifySelf: 'center',
                    display: loading ? 'none' : 'block',
                }}
                onLoad={() => setLoading(false)} // Hide skeleton when image loads
            />
        </Box>
    );
}

function ProfilePictureWrapper({modalData}) {
    return modalData?.profilePicture ? (
        <ProfilePicture src={modalData.profilePicture}/>
    ) : (
        <p>Profile Picture not available</p>
    );
}

export default DriverDetailsModal;
