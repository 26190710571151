import {useState} from 'react';
import {Box, Skeleton} from '@mui/material';
import PdfViewer from "./PdfViewer";
import ImageViewer from "./ImageViewer";

function FileViewer({fileUrl, height}) {
    const [loading, setLoading] = useState(true);

    const isImage = /\.(jpg|jpeg|png|gif)$/i.test(fileUrl);
    const isPdf = /\.pdf$/i.test(fileUrl);

    const handleLoad = () => setLoading(false); // Called when file has fully loaded

    return (
        <Box sx={{width: '100%', position: 'relative'}}>
            {loading && (
                <Skeleton
                    variant="rectangular"
                    sx={{width: '100%', height: 400,}} // Adjust height to match the PDF or image
                />
            )}
            {isImage && (
                <ImageViewer src={fileUrl} alt="Image file" onLoad={handleLoad}/>
            )}
            {isPdf && (
                <PdfViewer fileUrl={fileUrl} onLoad={handleLoad}/>
            )}
        </Box>
    );
}

export default FileViewer;
