/**
 * Function to calculate the geographical centroid (average latitude and longitude)
 * @param {Array} points - Array of points, each represented as an object with `lat` and `lng` properties
 * @returns {Object} The centroid of the given points in the form { lat: X, lng: Y }
 */
export const getCenterOfPoints = (markers) => {
    const totalMarkers = markers.length;

    // If there are no markers, return Cary coordinates
    if (totalMarkers === 0) {
        return {lat: 35.7915, lng: -78.7811}; // Cary, NC coordinates
    }

    const center = markers.reduce(
        (acc, marker) => {
            acc.lat += marker.position.lat;
            acc.lng += marker.position.lng;
            return acc;
        },
        {lat: 0, lng: 0}
    );

    const avgCenter = {
        lat: center.lat / totalMarkers,
        lng: center.lng / totalMarkers
    };

    // If calculated center is at zero, return Cary coordinates
    if (avgCenter.lat === 0 && avgCenter.lng === 0) {
        return {lat: 35.7915, lng: -78.7811}; // Cary, NC coordinates
    }

    //console.log(avgCenter);
    return avgCenter;
};

export const getBoundingBoxOfMarkers = (markers) => {
    const bounds = markers.reduce(
        (acc, marker) => {
            const {lat, lng} = marker.position;
            acc.north = Math.max(acc.north, lat);
            acc.south = Math.min(acc.south, lat);
            acc.east = Math.max(acc.east, lng);
            acc.west = Math.min(acc.west, lng);
            return acc;
        },
        {
            north: -Infinity,
            south: Infinity,
            east: -Infinity,
            west: Infinity
        }
    );

    return bounds;
};
