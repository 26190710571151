import React, { useEffect, useState } from "react";
import axios from "axios";
import { Box, Button, Typography, Skeleton } from "@mui/material";
import toast from "react-hot-toast";
import PromotionCodeCard from "./PromotionCodeDard";

const PromotionCodesList = () => {
    const [promotionCodes, setPromotionCodes] = useState([]);
    const [loading, setLoading] = useState(true);

    // Fetch promotion codes from the backend API
    const fetchPromotionCodes = async () => {
        try {
            const response = await axios.get("/promotion-codes"); // API endpoint to fetch promotion codes
            setPromotionCodes(response.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error("Failed to fetch promotion codes.");
        }
    };

    useEffect(() => {
        fetchPromotionCodes();
    }, []);

    const handleDelete = async (id) => {
        try {
            await axios.delete(`/promotion-codes/${id}`); // API endpoint to delete a promotion code
            setPromotionCodes(promotionCodes.filter((code) => code._id !== id)); // Remove deleted code from the list
            toast.success("Promotion code deleted.",{  icon: '✅',  // Optional icon
                style: {
                background: '#4CAF50',  // Green background for success
                    color: 'white',          // White text color
            }});
        } catch (err) {
            toast.error("Failed to delete promotion code.");
        }
    };

    const handleEdit = (code) => {
        //console.log("Editing code:", code);
        // Handle the editing logic here
    };

    const codes = loading ? (
        <Box display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="rectangular" width="100%" height={200} />
        </Box>
    ) : (
        <Box>
            {promotionCodes.length === 0 ? (
                <Typography>No promotion codes found</Typography>
            ) : (
                <Box display="flex" flexWrap="wrap" gap={2}>
                    {promotionCodes.map((code) => (
                        <PromotionCodeCard
                            key={code._id}
                            code={code}
                            onEdit={() => handleEdit(code)}
                            onDelete={() => handleDelete(code._id)}
                        />
                    ))}
                </Box>
            )}
        </Box>
    );

    return (
        <Box>

            {codes}
        </Box>
    );
};

export default PromotionCodesList;
