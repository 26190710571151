import React, { useEffect, useRef, useState } from "react";
import { Box, Button, IconButton, Modal, Skeleton, TextField, Typography } from "@mui/material";
import { DrawingManager, GoogleMap, LoadScript } from "@react-google-maps/api";
import { GOOGLE_MAPS_API_KEY } from "../../constants";
import DeleteIcon from "@mui/icons-material/Delete";
import axios from "axios";
import toast from "react-hot-toast";
import CloseIcon from '@mui/icons-material/Close';
import CoverageAreaCard from "../components/CoverageAreaCard";

// Memoize GoogleMap to avoid unnecessary re-renders
const MemoizedGoogleMap = React.memo(({ onLoad, onPolygonComplete }) => {
    return (
        <GoogleMap
            mapContainerStyle={{ width: "100%", height: "500px" }}
            center={{ lat: 37.0902, lng: -95.7129 }} // Initial fixed center
            zoom={4}
            onLoad={onLoad}
        >
            <DrawingManager
                onPolygonComplete={onPolygonComplete}
                options={{
                    drawingControl: true,
                    drawingControlOptions: {
                        drawingModes: ["polygon"],
                    },
                    polygonOptions: {
                        editable: true,
                    },
                }}
            />
        </GoogleMap>
    );
});

 const CoverageArea = () => {
    const [open, setOpen] = useState(false);
    const [name, setName] = useState("");
    const [description, setDescription] = useState(""); // State for description
    const [polygonPath, setPolygonPath] = useState([]);
    const [nameError, setNameError] = useState(""); // Error state for name
    const [descriptionError, setDescriptionError] = useState(""); // Error state for description
    const [polygonError, setPolygonError] = useState(""); // Error state for polygon
    const mapRef = useRef();
    const polygonRef = useRef(null);

    const handleOpen = () => setOpen(true);
    const handleClose = () => {
        setName("");
        setDescription(""); // Clear description on close
        setPolygonPath([]);
        setNameError(""); // Clear name error on close
        setDescriptionError(""); // Clear description error on close
        setPolygonError(""); // Clear polygon error on close
        if (polygonRef.current) {
            polygonRef.current.setMap(null); // Remove polygon from the map
            polygonRef.current = null;
        }
        setOpen(false);
    };

    const handlePolygonComplete = (polygon) => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
        }

        const path = polygon.getPath().getArray().map((latLng) => ({
            lat: latLng.lat(),
            lng: latLng.lng(),
        }));
        setPolygonPath(path);
        polygonRef.current = polygon; // Store reference to polygon

        setPolygonError("");

        const bounds = new window.google.maps.LatLngBounds();
        path.forEach(({ lat, lng }) => bounds.extend(new window.google.maps.LatLng(lat, lng)));
        mapRef.current.fitBounds(bounds);
    };

    const handleRemovePolygon = () => {
        if (polygonRef.current) {
            polygonRef.current.setMap(null);
            polygonRef.current = null;
            setPolygonPath([]);
            setPolygonError("");
        }
    };

    const handleSubmit = async () => {
        let valid = true;

        if (!name.trim()) {
            setNameError("Coverage Area Name is required");
            valid = false;
        } else {
            setNameError("");
        }

        if (!description.trim()) {
            setDescriptionError("Description is required");
            valid = false;
        } else {
            setDescriptionError("");
        }

        if (polygonPath.length === 0) {
            setPolygonError("Polygon is required");
            valid = false;
        } else {
            setPolygonError("");
        }

        if (valid) {
            const data = { name, description, coordinates: polygonPath };
            try {
                toast.loading("Submitting...", { id: "coverage" });
                const response = await axios.post("/save-coverage-area", data);

                if (response.status === 201) {
                    toast.success("Coverage area created", { id: "coverage" ,  icon: '✅',  // Optional icon
                        style: {
                            background: '#4CAF50',  // Green background for success
                            color: 'white',          // White text color
                        }});
                    fetchCoverageAreas();
                    handleClose();
                } else {
                    toast.error("Error saving data", { id: "coverage" });
                }
            } catch (error) {
                toast.error("Error saving data", { id: "coverage" });
            }
        }
    };

    const modal = (
        <Modal open={open} onClose={handleClose}>
            <Box sx={{ padding: 4, backgroundColor: "white", width: '90%', margin: "auto", mt: 4, borderRadius: 2 }}>
                <Box display="flex" justifyContent="space-between" alignItems="center" mb={1}>
                    <Typography variant="h3" sx={{ flexGrow: 1, textAlign: 'center' }}>
                        Add Coverage Area
                    </Typography>
                    <IconButton onClick={handleClose}>
                        <CloseIcon />
                    </IconButton>
                </Box>
                <TextField
                    label="Coverage Area Name"
                    value={name}
                    onChange={(e) => setName(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!nameError}
                    helperText={nameError}
                />
                <TextField
                    label="Description"
                    value={description}
                    onChange={(e) => setDescription(e.target.value)}
                    fullWidth
                    margin="normal"
                    error={!!descriptionError}
                    helperText={descriptionError}
                />
                {polygonPath.length > 0 && (
                    <IconButton color="secondary" onClick={handleRemovePolygon}>
                        <DeleteIcon />
                    </IconButton>
                )}
                {typeof window.google !== 'undefined' ? (
                    <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)} onPolygonComplete={handlePolygonComplete} />
                ) : (
                    <LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing",'places']}>
                        <MemoizedGoogleMap onLoad={(map) => (mapRef.current = map)} onPolygonComplete={handlePolygonComplete} />
                    </LoadScript>
                )}
                {polygonError && (
                    <Typography color="error" variant="body2" sx={{ mt: 1 }}>
                        {polygonError}
                    </Typography>
                )}
                <Button variant="contained" color="primary" onClick={handleSubmit} fullWidth sx={{ mt: 2 }}>
                    Submit
                </Button>
            </Box>
        </Modal>
    );

    // Fetch and display areas (remains the same)
    const [coverageAreas, setCoverageAreas] = useState([]);
    const [loading, setLoading] = useState(true);
    const fetchCoverageAreas = async () => {
        try {
            const response = await axios.get("/coverage-areas");
            setCoverageAreas(response.data);
            setLoading(false);
        } catch (err) {
            setLoading(false);
            toast.error("Failed to fetch coverage areas.");
        }
    };
    useEffect(() => { fetchCoverageAreas(); }, []);
    const handleEdit = (area) => { console.log("Editing area:", area); };
    const handleDelete = async (id) => {
        try {
            await axios.delete(`/coverage-areas/${id}`);
            setCoverageAreas(coverageAreas.filter((area) => area._id !== id));
            toast.success("Coverage area deleted.",{
                icon: '✅',  // Optional icon
                style: {
                    background: '#4CAF50',  // Green background for success
                    color: 'white',          // White text color
                }
            });
        } catch (err) {
            toast.error("Failed to delete coverage area.");
        }
    };

    const areas = loading ? (
        <Box display="flex" flexDirection="column" gap={2}>
            <Skeleton variant="text" width="60%" height={40} />
            <Skeleton variant="rectangular" width="100%" height={200} />
        </Box>
    ) : (
        <Box>
            <Typography variant="h4" gutterBottom>Coverage Areas</Typography>
            {coverageAreas.length === 0 ? <Typography>No coverage areas found</Typography> : (
                <Box display="flex" flexWrap="wrap" gap={2}>
                    {coverageAreas.map((area) => (
                        <CoverageAreaCard key={area._id} area={area} onEdit={handleEdit} onDelete={() => handleDelete(area._id)} />
                    ))}
                </Box>
            )}
        </Box>
    );

    return (
        <>
            <Box display="flex" justifyContent="flex-end" mb={2}>
                <Button variant="contained" color="primary" onClick={handleOpen}>Add Coverage Area</Button>
            </Box>
            {modal}
            {areas}
        </>
    );
};
export  default CoverageArea