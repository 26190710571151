import React, {useState} from "react";
import {Box, Button, Divider, Grid, Modal, Stack, Typography} from "@mui/material";
import {CloseOutlined} from "@mui/icons-material";
import axios from "axios";
import toast from "react-hot-toast";
import {useTranslation} from "react-i18next";
import Datagrid from "../components/Datagrid";

const style = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 1,
    height: "70vh",
    overflowY: "auto",
};

const Corporates = () => {
    const [open, setOpen] = useState(false);
    const [corporateData, setCorporateData] = useState({});
    const {t} = useTranslation();
    const [refetch, setRefetch] = useState(1);

    const handleClose = () => {
        setOpen(false);
    };

    const handleOpen = async (data) => {

        setCorporateData(data);
        setOpen(true);

    };

    const verifyCorporate = async (id) => {
        toast.loading("Verifying", {id: "corporates"});
        axios.post(`/verifyCorporate`,{id})
            .then(() => {
                toast.success("Verified Successfully", {id: "corporates",  icon: '✅',  // Optional icon
                    style: {
                        background: '#4CAF50',  // Green background for success
                        color: 'white',          // White text color
                    }});
                handleClose();
                setRefetch(refetch + 1);
            })
            .catch(() => {
                toast.error("Unable to Verify", {id: "corporates"});
            });
    };

    const columns = [
        {
            headerName: "Company Name",
            width: 200,
            renderCell: (params) => (
                <Typography variant="body">{params.row?.companyName}</Typography>
            ),
        },
        {
            field: "contactPerson",
            renderCell: (params) => (
                <Typography variant="body">{params.row?.contactPerson}</Typography>
            ),
            headerName: "Contact Person",
            width: 200,
        },
        {
            field: "contactEmail",
            renderCell: (params) => (
                <Typography variant="body">{params.row?.contactEmail}</Typography>
            ),
            headerName: "Email",
            width: 300,
        },
        {
            field: "contactPhone",
            renderCell: (params) => (
                <Typography variant="body">{params.row?.contactPhone}</Typography>
            ),
            headerName: "Phone",
            width: 150,
        },
        {
            field: "isVerified",
            renderCell: (params) => (
                <Typography variant="body" color={!params.row?.isVerified && "error"}>
                    {params.row?.isVerified ? "Verified" : "Pending"}
                </Typography>
            ),
            headerName: "Verified",
            width: 150,
        },
        {
            headerName: "Details",
            field: "id",
            sortable: false,
            width: 125,
            renderCell: (params) => (
                <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => handleOpen(params.row)}
                >
                    Details
                </Button>
            ),
        },
    ];

    return (
        <>
            <Typography variant="h1" mb={3}>
                {t("pages.title.corporates")}
            </Typography>

            <Grid container justifyContent="space-between">
                <Grid item></Grid>
                <Grid item></Grid>
            </Grid>

            {corporateData && (
                <Modal
                    open={open}
                    onClose={handleClose}
                    aria-labelledby="modal-modal-title"
                    aria-describedby="modal-modal-description"
                >
                    <Box sx={style}>
                        <Grid container justifyContent="space-between">
                            <Grid>
                                <Typography variant="h5" fontWeight="500" marginBottom={4}>
                                    Corporate Details
                                </Typography>
                            </Grid>
                            <Grid>
                                <Button sx={{color: "black"}} onClick={handleClose}>
                                    <CloseOutlined/>
                                </Button>
                            </Grid>
                        </Grid>

                        <Grid>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Company Name</Typography>
                                <Typography fontSize="16px">{corporateData.companyName}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Contact Person</Typography>
                                <Typography fontSize="16px">{corporateData.contactPerson}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Contact Email</Typography>
                                <Typography fontSize="16px">{corporateData.emailVerified}</Typography>
                            </Stack>  <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Email Verification</Typography>
                                <Typography fontSize="16px">{corporateData.contactEmail}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Contact Phone</Typography>
                                <Typography fontSize="16px">{corporateData.contactPhone}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Complete Address</Typography>
                                <Typography fontSize="16px">{corporateData.completeAddress}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Billing Contact Person</Typography>
                                <Typography fontSize="16px">{corporateData.billingContactPerson}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Billing Email</Typography>
                                <Typography fontSize="16px">{corporateData.billingEmail}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Billing Address</Typography>
                                <Typography fontSize="16px">{corporateData.billingAddress}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Payment Method</Typography>
                                <Typography fontSize="16px">{corporateData.paymentMethod}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Delegate Management</Typography>
                                <Typography
                                    fontSize="16px">{corporateData.delegateManagement ? 'Yes' : 'No'}</Typography>
                            </Stack>
                            <Stack direction="row" justifyContent="space-between" margin="20px 0 10px 0" spacing="20px">
                                <Typography fontSize="16px" fontWeight="500">Billing Option</Typography>
                                <Typography fontSize="16px">{corporateData.billingOption}</Typography>
                            </Stack>
                            <Divider/>
                        </Grid>
                        <Grid container columnGap={5} marginBottom="50px">
                            <Typography color="error">{}</Typography>
                        </Grid>
                        <Grid container justifyContent="center">
                            <Button
                                variant="contained"
                                disabled={corporateData?.isVerified}
                                sx={{width: "300px"}}
                                onClick={() => verifyCorporate(corporateData?._id)}
                            >
                                {corporateData?.isVerified ? "Verified" : "Verify"}
                            </Button>
                        </Grid>
                    </Box>
                </Modal>
            )}
            <Datagrid url="/corporates/v1" columns={columns} refetch={refetch}/>
        </>
    );
};

export default Corporates;
