import React from 'react';
import {Button, FormControl, Grid, MenuItem, Select, TextField, Typography} from '@mui/material';
import * as Yup from 'yup';
import {useFormik} from 'formik';

// Validation schema using Yup
const validationSchema = Yup.object({
    insuranceStatus: Yup.string().required('Insurance status is required'),
    insuranceRemarks: Yup.string(),
    licenseStatus: Yup.string().required('License status is required'),
    licenseRemarks: Yup.string(),
    pictureStatus: Yup.string().required('Picture status is required'),
    pictureRemarks: Yup.string(),
});

const VerificationForm = ({driverId, documentVerification, updateDriverVerificationStatus}) => {
    //console.log(documentVerification.documentVerification)
    const formik = useFormik({
        initialValues: {
            insuranceStatus: documentVerification.documentVerification.insuranceStatus,
            insuranceRemarks: documentVerification.documentVerification.insuranceRemarks,
            licenseStatus: documentVerification.documentVerification.licenseStatus,
            licenseRemarks: documentVerification.documentVerification.licenseRemarks,
            pictureStatus: documentVerification.documentVerification.pictureStatus,
            pictureRemarks: documentVerification.documentVerification.pictureRemarks,
        },
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            updateDriverVerificationStatus(driverId, values);

        }
    });

    return (
        <Grid container spacing={2} justifyContent='center'>
            <Grid item xs={3}>
                <Typography variant="body2">Car Insurance Document</Typography>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Select
                                name="insuranceStatus"
                                value={formik.values.insuranceStatus}
                                onChange={formik.handleChange}
                                displayEmpty
                                size="small"
                                error={formik.touched.insuranceStatus && Boolean(formik.errors.insuranceStatus)}
                            >
                                <MenuItem value="" disabled>Select Status</MenuItem>
                                <MenuItem value="Approved">Approved</MenuItem>
                                <MenuItem value="Resubmit">Resubmit </MenuItem>
                                <MenuItem value="Pending">Pending </MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Remarks"
                            fullWidth
                            name="insuranceRemarks"
                            value={formik.values.insuranceRemarks}
                            onChange={formik.handleChange}
                            size="small"
                            error={formik.touched.insuranceRemarks && Boolean(formik.errors.insuranceRemarks)}
                            // helperText={formik.touched.insuranceRemarks && formik.errors.insuranceRemarks}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* License */}
            <Grid item xs={3}>
                <Typography variant="body2">Driving License</Typography>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Select
                                name="licenseStatus"
                                value={formik.values.licenseStatus}
                                onChange={formik.handleChange}
                                displayEmpty
                                size="small"
                                error={formik.touched.licenseStatus && Boolean(formik.errors.licenseStatus)}
                            >
                                <MenuItem value="" disabled>Select Status</MenuItem>
                                <MenuItem value="Approved">Approved</MenuItem>
                                <MenuItem value="Resubmit">Resubmit</MenuItem>
                                <MenuItem value="Pending">Pending</MenuItem>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Remarks"
                            fullWidth
                            name="licenseRemarks"
                            value={formik.values.licenseRemarks}
                            onChange={formik.handleChange}
                            size="small"
                            error={formik.touched.licenseRemarks && Boolean(formik.errors.licenseRemarks)}
                            // helperText={formik.touched.licenseRemarks && formik.errors.licenseRemarks}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* Picture */}
            <Grid item xs={3}>
                <Typography variant="body2">Profile Picture</Typography>
            </Grid>
            <Grid item xs={8}>
                <Grid container spacing={1} alignItems="center">
                    <Grid item xs={4}>
                        <FormControl fullWidth>
                            <Select
                                name="pictureStatus"
                                value={formik.values.pictureStatus}
                                onChange={formik.handleChange}
                                displayEmpty
                                size="small"
                                error={formik.touched.pictureStatus && Boolean(formik.errors.pictureStatus)}
                            >
                                <MenuItem value="" disabled>Select Status</MenuItem>
                                <MenuItem value="Approved">Approved</MenuItem>
                                <MenuItem value="Resubmit">Resubmit</MenuItem>
                                <MenuItem value="Pending">Pending</MenuItem>

                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={8}>
                        <TextField
                            label="Remarks"
                            fullWidth
                            name="pictureRemarks"
                            value={formik.values.pictureRemarks}
                            onChange={formik.handleChange}
                            size="small"
                            error={formik.touched.pictureRemarks && Boolean(formik.errors.pictureRemarks)}
                            // helperText={formik.touched.pictureRemarks && formik.errors.pictureRemarks}
                        />
                    </Grid>
                </Grid>
            </Grid>

            {/* Submit Button */}
            <Grid item xs={8}>
                <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={formik.handleSubmit}
                >
                    Submit
                </Button>
            </Grid>

            {/* Status Message */}

        </Grid>
    );
};

export default VerificationForm;
