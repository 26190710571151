import React, {useState} from "react";

import {Typography} from "@mui/material";
import Datagrid from "../components/Datagrid";
import {useTranslation} from "react-i18next";
import MaleIcon from "@mui/icons-material/Male";
import FemaleIcon from "@mui/icons-material/Female";
import HourglassEmptyIcon from '@mui/icons-material/HourglassEmpty';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import PauseCircleIcon from '@mui/icons-material/PauseCircle';
import CreditCardOffIcon from '@mui/icons-material/CreditCardOff';
import HelpIcon from '@mui/icons-material/Help';
import Chip from "@mui/material/Chip";
import CopyWrapper from "../shared/CopyWrapper";

const Payments = () => {
    const columns = [
        {
            field: "paymentFor",
            headerName: "Service",
            width: 150,
            renderCell: (params) => {
                const capitalizeWords = (text) => {
                    return text
                        .split(' ') // Split the text into words
                        .map(word => word.charAt(0).toUpperCase() + word.slice(1).toLowerCase()) // Capitalize the first letter of each word
                        .join(' '); // Join the words back into a string
                };

                const serviceName = params.row?.paymentFor;
                const formattedServiceName = serviceName ? capitalizeWords(serviceName) : '';

                return <span>{formattedServiceName}</span>;
            }
        },
        {
            field: "payeeId",
            headerName: "Payer",
            width: 150,
            renderCell: (params) => (
                <Typography variant="body">{params.row.payerId?.name}</Typography>
            ),
        },
        {
            field: "payerId",
            headerName: "Paye",
            width: 150,
            renderCell: (params) => (
                <Typography variant="body">{params.row.payeeId?.name}</Typography>
            ),
        },
        {
            field: "status",
            headerName: "Status",
            width: 150,
            renderCell: (params) => {
                const status = params.row?.status;

                const getStatusProps = () => {
                    switch (status) {
                        case 'pending':
                            return { label: "Pending", icon: <HourglassEmptyIcon style={{ color: "#ff9800" }} /> };
                        case 'completed':
                            return { label: "Completed", icon: <CheckCircleIcon style={{ color: "#4caf50" }} /> };
                        case 'hold':
                            return { label: "On Hold", icon: <PauseCircleIcon style={{ color: "#ff5722" }} /> };
                        case 'refunded':
                            return { label: "Refunded", icon: <CreditCardOffIcon style={{ color: "#f44336" }} /> };
                        default:
                            return { label: "Unknown", icon: <HelpIcon style={{ color: "#9e9e9e" }} /> };
                    }
                };

                const { label, icon } = getStatusProps();

                return (
                    <Chip
                        label={label}
                        icon={icon}
                        variant="outlined"
                        size="medium"
                    />
                );
            }
        },      {
            field: "stripePaymentIntentID",
            headerName: "Stripe Intent Id",
            width: 300,
            renderCell: (params) => {
                const stripePaymentIntentID = params.row?.stripePaymentIntentID;

                return <CopyWrapper  >{stripePaymentIntentID}</CopyWrapper>;
            }
        },

        {
            headerName: "Time",
            field: "_id",
            sortable: false,
            width: 175,
            renderCell: (params) => (
                <Typography variant="body">
                    {new Date(params.row.createdAt).toLocaleString()}
                </Typography>
            ),
        },
    ];
    const [refetch, setRefetch] = useState(1);


    const {t} = useTranslation();
    return (
        <>
            <Typography variant="h1" mb={3}>
                {t("pages.title.payments")}
            </Typography>

            {/* Modal  */}

            <Datagrid url={"/payments"} columns={columns} refetch={refetch}/>
        </>
    );
};

export default Payments;
