// CoverageAreaCard.js
import React, {useRef} from "react";
import {Button, Card, CardActions, CardContent, Typography} from "@mui/material";
import {GoogleMap, LoadScript, Polygon} from "@react-google-maps/api";
import {GOOGLE_MAPS_API_KEY} from "../../constants";

const SurgeAreaCard = ({area, onEdit, onDelete}) => {

    //console.log(area)
    const mapContainerStyle = {width: "100%", height: "140px"};
    const mapRef = useRef(null);

    const onLoadMap = (map) => {
        mapRef.current = map;
        fitBounds(map);
    };

    const fitBounds = (map) => {
        if (area.coordinates.length) {
            const bounds = new window.google.maps.LatLngBounds();
            area.coordinates.forEach((coord) => bounds.extend(coord));
            map.fitBounds(bounds);
        }
    };

    return (
        <Card sx={{width: 345 , marginTop:2}} >
            {/* Google Map Displaying the Polygon */}
            {typeof window.google !== 'undefined' ? <GoogleMap
                mapContainerStyle={mapContainerStyle}
                onLoad={onLoadMap}
                zoom={8} //
                options={{disableDefaultUI: false, keyboardShortcuts: false,}}
                // Initial zoom; will be adjusted by fitBounds
            >
                <Polygon
                    path={area?.coordinates}
                    options={{
                        fillColor: "#2196f3",
                        fillOpacity: 0.3,
                        strokeColor: "#2196f3",
                        strokeOpacity: 1,
                        strokeWeight: 2,
                    }}
                />
            </GoogleMap> : (<LoadScript googleMapsApiKey={GOOGLE_MAPS_API_KEY} libraries={["drawing",'places']}>
                <GoogleMap
                    mapContainerStyle={mapContainerStyle}
                    onLoad={onLoadMap}
                    zoom={8} //
                    // Initial zoom; will be adjusted by fitBounds
                >
                    <Polygon
                        path={area?.coordinates}
                        options={{
                            fillColor: "#2196f3",
                            fillOpacity: 0.3,
                            strokeColor: "#2196f3",
                            strokeOpacity: 1,
                            strokeWeight: 2,
                        }}
                    />
                </GoogleMap>
            </LoadScript>)}

            <CardContent>
                {/*<Typography variant="h6">Surge Area Details</Typography>*/}
                <Typography variant="body1"><strong>Surge Area Name:</strong> {area?.name}</Typography>
                <Typography variant="body1"><strong>Description:</strong> {area?.description}</Typography>
                <Typography variant="body1"><strong>Surge Factor:</strong> {area?.surgeFactor}</Typography>
                <Typography variant="body1"><strong>Start Time:</strong> {new Date(area?.startTime).toLocaleString()}</Typography>
                <Typography variant="body1"><strong>End Time:</strong> {new Date(area?.endTime).toLocaleString()}</Typography>
            </CardContent>
            <CardActions>
                {/*<Button size="small" onClick={() => onEdit(area)}>Edit</Button>*/}
                <Button size="small" color="secondary" onClick={() => onDelete(area._id)}>
                    Delete
                </Button>
            </CardActions>
        </Card>
    );
};

export default SurgeAreaCard;
