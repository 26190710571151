import React, {useEffect, useState} from 'react';
import {Card, Grid, Typography} from "@mui/material";
import {GoogleMap, InfoWindow, Marker, useJsApiLoader} from '@react-google-maps/api';
import {useTheme} from "@mui/material/styles";
import {useTranslation} from "react-i18next";
import {GOOGLE_MAPS_API_KEY} from "../../../constants";
import carIconImage from './car-icon.svg';
import {getCenterOfPoints} from "./GetCentrePoint"; // Adjust the path as needed
import io from 'socket.io-client';
import {useAuth} from "../../context/AuthContext"; // Import the js-cookie library


const containerStyle = {
    width: '100%',
    height: '500px'
};

const SalesStatistics = ({regByMonth, ridesByMonth, updateTime}) => {
    const {t} = useTranslation();
    const theme = useTheme();
    const {accessToken} = useAuth()
    const carIcon = {
        url: carIconImage, // Replace with your car icon URL
        scaledSize: {width: 32, height: 32}  // Adjust size of the icon
    };

    const [markers, setMarkers] = useState([
        // {id: 4, position: {lat: -3.775, lng: -38.543}, name: 'Marker 3', info: 'Details about Marker 3'},

    ]);

    // const accessToken = Cookies.get('accessToken'); // Retrieve access token from cookies

    const [activeMarker, setActiveMarker] = useState(null);
    const socket = io('https://nearbydrivers.iceride.io/', {
        query: {accessToken}, // Pass the access token as a query parameter
        reconnection: true, // Enable reconnection
        reconnectionAttempts: Infinity, // Unlimited reconnection attempts
        reconnectionDelay: 1000, // Delay before attempting to reconnect (in milliseconds)
        reconnectionDelayMax: 5000, // Max delay for reconnection attempts
        timeout: 20000, // Connection timeout before assuming it failed

    }); // Replace with your Socket.IO server URL

    useEffect(() => {
        // Emit adminConnect event when connected
        socket.emit('adminConnect');

        // Listen for driverLocationUpdate events
        socket.on('driverLocationUpdate', (data) => {
            // Check if the data is valid and contains necessary fields
            //console.log(data)
            // if (data) {
            //     // Add a new marker for the driver
            //     setMarkers(prevMarkers => [
            //         ...prevMarkers,
            //         {
            //             id: data.driverId,
            //             position: {lat: data.lat, lng: data.lng},
            //             name: `Driver ${data.id}`,
            //             info: 'Driver is active'
            //         }
            //         // {id: 4, position: {lat: -3.775, lng: -38.543}, name: 'Marker 3', info: 'Details about Marker 3'},
            //
            //     ]);
            // }
            if (data) {
                setMarkers(prevMarkers => {
                    // Check if the marker already exists
                    const markerExists = prevMarkers.some(marker => marker.id === data.driverId);

                    if (markerExists) {
                        // Update the existing marker
                        return prevMarkers.map(marker =>
                            marker.id === data.driverId
                                ? {
                                    ...marker,
                                    position: {lat: data.lat, lng: data.lng}, // Update marker position
                                    name: `Driver ${data.driverId}` // Update any other info if needed
                                }
                                : marker
                        );
                    } else {
                        // Add a new marker
                        return [
                            ...prevMarkers,
                            {
                                id: data.driverId,
                                position: {lat: data.lat, lng: data.lng},
                                name: `Driver ${data.driverId}`,
                                info: 'Driver is active'
                            }
                        ];
                    }
                });
            }
        });
        socket.on('driverOffline', (data) => {
            //console.log(data)

            if (data) {
                setMarkers(prevMarkers => {
                    // Check if the marker already exists
                    const markerExists = prevMarkers.some(marker => marker.id === data.driverId);

                    if (markerExists) {
                        // Remove the existing marker and add the updated one
                        return [
                            ...prevMarkers.filter(marker => marker.id !== data.driverId), // Remove the old marker
                            {
                                id: data.driverId,
                                position: {lat: data.lat, lng: data.lng}, // Update marker position
                                name: `Driver ${data.driverId}`,
                                info: 'Driver is active' // Update any other info if needed
                            }
                        ];
                    }
                });
            }
        });
// todo remove markersset anchor issue
        // Clean up on component unmount
        return () => {
            socket.disconnect();
        };
    }, []);

    const handleMarkerClick = (marker) => {
        setActiveMarker(marker);
    };

    const handleMapClick = () => {
        setActiveMarker(null); // Close the info window on map click
    };
    const libraries = ['places','drawing'];

    const {isLoaded, loadError} = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: GOOGLE_MAPS_API_KEY,
        libraries,
    });
    // Common map setup
    const renderMap = () => (
        <GoogleMap
            mapContainerStyle={containerStyle}
            center={getCenterOfPoints(markers) || {lat: 35.77720015678632, lng: -78.63463253557084}}
            zoom={12}
            options={{
                // disableDefaultUI:true
                streetViewControl: false, scrollwheel: true, controlSize: 30, zoomControl: false, mapTypeControl: false, scaleControl: true,
            }}
            // onLoad={map => {
            //     const bounds = new window.google.maps.LatLngBounds();
            //     markers.forEach(marker => {
            //         bounds.extend(marker.position);
            //     });
            //     map.fitBounds(bounds);
            // }}
            onClick={handleMapClick}
        >
            {markers.map(marker => (
                <Marker
                    key={marker.id}
                    position={marker.position}
                    icon={carIcon}
                    title={marker.name}
                    onClick={() => handleMarkerClick(marker)} // Handle marker click
                />
            ))}
            {activeMarker && (
                <InfoWindow
                    position={activeMarker.position}
                    onCloseClick={handleMapClick}
                >
                    <div>
                        <h2>{activeMarker.name}</h2>
                        <p>{activeMarker.info}</p>
                    </div>
                </InfoWindow>
            )}
        </GoogleMap>
    );
    //console.log("Markers =>>", markers)
    return (
        <Card>
            <Typography
                variant={"h5"}
                color={"common.white"}
                sx={{backgroundColor: theme.palette.primary.main, padding: theme.spacing(2), marginBottom: 0}}
            >
                {t('widgets.title.map')}
            </Typography>
            <Grid container>
                <Grid item xs={12}>
                    {isLoaded ? (
                        renderMap()
                    ) : (
                        <>
                            {/*<Loade*/}
                        </>

                    )}
                </Grid>
            </Grid>
        </Card>
    );
};

export default React.memo(SalesStatistics);
